<template>
  <WeModal
    modal-width="80"
    icon="fas fa-file-excel"
    title="Excel Aktarma Sihirbazı"
    v-on:close="onClose"
  >
    <div
      slot="body"
      class="custom-scrollbar col-12"
      style="max-height: 650px; overflow: auto"
    >
      <template v-if="useLanguage">
        <div class="mb-3">
          <label class="custom-label">İçerik Dili Seçimi</label>
          <div class="d-block mb-1">
            <small class="text-muted"
              >Export alacağınız verilerin hangi dilde olacağını seçiniz.</small
            >
          </div>
          <WeLanguageSwitcher
            v-bind:value="language"
            v-on:input="onChangeLanguage"
          />
        </div>
      </template>
      <div class="row">
        <!-- Selectable Fields -->
        <div class="col-12 col-lg mb-2 mb-lg-0">
          <WeCard>
            <div class="row">
              <div class="col">
                <h6 class="custom-card-title mb-3">
                  <i class="fas fa-check-double"></i>
                  <span>Sütunlar</span>
                </h6>
              </div>
              <div class="col-12 mb-2 mb-lg-0 col-lg-auto">
                <WeSwitch label="Tümünü Seç" v-model="useAllFields" />
              </div>
              <div class="col-12 mb-2 mb-lg-0 col-lg-auto" v-if="isVariant">
                <WeSwitch label="Varyasyon Listesi" v-model="useVariant" />
              </div>
            </div>
            <ul
              class="list-group custom-scrollbar"
              style="max-height: 450px; overflow: auto"
            >
              <li
                class="list-group-item hover-background-indigo"
                v-for="field in getFields"
                v-bind:key="field.key"
              >
                <WeSwitch
                  v-bind:label="field.value"
                  v-bind:value="getSelected(field)"
                  v-bind:disabled="checkSelectable(field)"
                  v-on:input="onSwitch(field, $event)"
                />
              </li>
            </ul>
          </WeCard>
        </div>
        <!-- ./Selectable Fields -->

        <!-- Fields Order -->
        <div class="col-12 col-lg-6" v-if="selected.length">
          <WeCard>
            <h6 class="custom-card-title mb-3">
              <i class="fas fa-grip-vertical"></i>
              <span>Sıralama</span>
            </h6>
            <SelectedItems
              v-model="selected"
              class="custom-scrollbar"
              style="max-height: 450px; overflow: auto"
            />
          </WeCard>
        </div>
        <!-- ./Fields Order -->
      </div>
      <!-- Download Button -->
      <div class="text-center mt-3">
        <button class="btn btn-success" v-on:click="onClose(true)">
          <i class="fas fa-download mr-2"></i> Excel'e Aktar
        </button>
      </div>
      <!-- ./Download Button -->
    </div>
  </WeModal>
</template>
<script>
import { mapState } from "vuex";
const SelectedItems = () => import("./components/SelectedItems/Index.vue");
export default {
  name: "WeListExport",
  props: {
    fieldKey: {
      default: "product",
    },
    fixedSelected: {
      default: () => [],
    },
    requiredKeys: {
      default: () => [],
    },
    isVariant: {
      default: false,
    },
    useLanguage: {
      default: false,
    },
  },
  components: {
    SelectedItems,
  },
  data() {
    return {
      language: "tr",
      selected: [],
      useVariant: false,
      useAllFields: false,
      variantKeys: ["variant_name", "variant_price", "variant_discount_price"],
    };
  },
  methods: {
    onChangeLanguage(lang) {
      this.language = lang;
    },
    onSwitch(field, status) {
      if (status) {
        this.selected.push(field);
      } else {
        const selectedIndex = this.selected.findIndex(
          (item) => item.key === field.key
        );
        this.selected.splice(selectedIndex, 1);
      }
    },
    getSelected(field) {
      return this.selected.filter((item) => item.key == field.key).length;
    },
    setSelected() {
      if (this.fixedSelected.length) {
        for (let i = 0; i < this.fixedSelected.length; i++) {
          const key = this.fixedSelected[i];
          if (!this.marketplaceConfig && key == "marketplaces") continue;
          const fieldFromStore = this.getFields.find((item) => item.key == key);
          if (!this.getSelected(fieldFromStore)) {
            const value = helper.clone(fieldFromStore);
            this.selected.push(value);
          }
        }
      }
    },
    onClose(download = false) {
      const data = {
        download: download,
        selected: this.selected,
        use_variant: this.useVariant,
        language: this.language,
      };

      this.$emit("close", data);
    },
    checkSelectable(field) {
      // disabled=result
      let result = false;

      if (!this.useVariant) {
        result = this.variantKeys.includes(field.key);
      } else {
        result = field.key == "variant_name";
      }

      // Zorunlu olan alanlarda disabled=true olacak
      if (this.requiredKeys.includes(field.key)) {
        result = true;
      }

      return result;
    },
  },
  computed: {
    ...mapState(["listexport", "session"]),
    marketplaceConfig() {
      let result = false;
      const marketplaceIntegrationKey =
        this.session.config["site.marketplace_integration"];
      if (typeof marketplaceIntegrationKey !== "undefined") {
        result =
          marketplaceIntegrationKey == "1" || marketplaceIntegrationKey == 1;
      }
      return result;
    },
    getFields() {
      let fields = helper.clone(this.listexport.fields[this.fieldKey]);
      if (!this.marketplaceConfig) {
        fields = fields.filter((item) => item.key !== "marketplaces");
      }

      return fields;
    },
  },
  watch: {
    useVariant: function (status) {
      if (!status) {
        this.selected = this.selected.filter(
          (item) => !this.variantKeys.includes(item.key)
        );
      } else {
        this.variantKeys.forEach((el) => {
          const foundKey = this.getFields.find((f) => f.key == el);
          const checkSelected = this.selected.findIndex((s) => s.key == el);
          if (foundKey && checkSelected < 0) {
            this.selected.push(helper.clone(foundKey));
          }
        });
      }
    },
    useAllFields: function (status) {
      if (status) {
        this.selected = helper.clone(this.getFields);
        if (this.isVariant) {
          this.useVariant = true;
        }
      } else {
        const allFields = helper.clone(this.getFields);
        this.selected = allFields.filter((field) =>
          this.requiredKeys.includes(field.key)
        );
        if (this.isVariant) {
          this.useVariant = false;
        }
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.setSelected();
    }, 500);
  },
};
</script>